/* Stylesheet für Selfhtml Design 03
  responsives Layout mit Grid Layout ab Z. 210   */

/* ====================================================   GLOBAL DEFINITION   ==================================================== */

/* alternatives Boxmodell */
html { box-sizing: border-box; /*background: #d5d5d5;	*/ }
*, ::before, ::after { box-sizing: inherit; }
body {
	width: 100%;
	min-width: 70em;
	margin:0 auto;
	padding: 0;
	background: rgb(228, 228, 228);
	color: #333;
	font: normal 1em Arial, sans-serif;  /* Mindestschriftgröße wird dem Browser, bzw. dem Nutzer überlassen! ACHTUNG steuert auch die Spaltenbreiten der Tabellen! */
	border-radius: 8px;
}
.top { vertical-align: top; }
/* ====================================================   HEADER   ==================================================== */

header { background: rgba(3, 60, 138, 1); padding: 0 0.4em 0.2em; border-radius: 0.5em; margin-bottom: 0.5em; /*background: rgb(201, 201, 201); /*url("../img/Design03-head.jpg") no-repeat right*/ }
header a h1,
header a p {color: white; border-left: 0; padding: 0; }	/*Die Angaben für h1 müssen mit einem genauen Selektor überschrieben werden. */
	/*display: table;		/* Überschriften und Absätze sind sonst immer 100% breit, durch display:table und display: inline-block; für das Elternelement a.ribbon wird das Banner nur so breit wie nötig. */
header img { width: 3em; /*border-left:3px solid rgba(3, 60, 138, 1);*/ }
header .arrow { width: 1em !important; }
header .head { display: inline-flex; width: 52%; }
header .logo { display: inline-flex; width: 5em !important; border-left: none !important; margin-top: 0.25em; }
header .headline { display: block; padding: 0.9em 1.2em; font-size: 1.8em; color: rgba(212, 212, 212, 1); width: 100%; }
header .headline select { font-size: 1em; background: rgb(201, 201, 201); color: rgba(3, 60, 138, 1); border-radius: 0.3em; }
.excelimage { width: 1.5em; margin-right: 1em; }

/*********** Main Navigation *****************/

nav#navigation{ float: right; vertical-align: top; width: 48%; }
#navigation div a { float: right; vertical-align: top; font-size: 1.1em; margin: 1em 1em; text-decoration: none; }

/* ====================================================   Side Navigation   ==================================================== */

nav#navigation_2{
	display: block;
	width: 5.5em;
	/*background: rgb(201, 201, 201); /*url("../img/Design03-head.jpg") no-repeat right*/
	background: rgba(3, 60, 138, 1);
	padding: 0.5em 0 0.5em;
	border-radius: 8px;
	min-height: 48em;
}
#navigation_2 div a { font-size: 1.1em; margin: 1em 0.5em; text-decoration: none; }
#navigation_2 img { width: 3em; margin-bottom: 1em; margin-left: 0.5em; }
.robot_old{
	background: rgb(201, 201, 201);
	color: rgb(3, 60, 138, 1);
	/*border: 1px solid rgb(3, 60, 138, 1);*/
	width: 3.5em !important;
	height: 3.5em;
	border-radius: 6px;
	padding: 0.2em;
	margin-top: 0.5em;
	margin-bottom: 0.5em !important;
	margin-left: 0.25em !important;
}
.nav2 { display: table-cell; width: 4em; }
.nav2Link { margin: 10px 10px; }
/* Navigation -Bottom */
#navigation_2 .bottom { position: absolute; bottom: 0px; left: 0px; margin-bottom: 0em !important; margin-top: 0.5em; }
#navigation_2 .nav_left_main { position: relative; min-height: 48em; }
#navigation_2 .nav_left_main_su { position: relative; min-height: 56em; }

/* ====================================================   BODY   ==================================================== */

.content { display: table-cell; vertical-align: top; padding: 0 0.5em 0 0.5em; width: 100%; }
.body { display: table; width: 100%; }
/* Table beside navigation */
.tablebody { width: 100%; }
/* add different table widths */
.table_100 { max-width: 100em; }
.table_80 { max-width: 80em; }
.table_70 { max-width: 70em; }
.table_60 { max-width: 60em; }
.table_40 { max-width: 40em; }
.table_stats { font-size: 0.6em; max-width: 100%; }
.table_small_font { font-size: 0.8em !important;}

/* ---------------------------------------- Home --------------------------------------------*/

.home_robots {
	display: inline-block;
	background: rgb(201, 201, 201);
	color: rgb(3, 60, 138, 1);
	border: 2px solid rgb(3, 60, 138, 1);
	border-radius: 12px;
	padding: 0.2em;
	width: 25em;
	height: 8.4em;
	margin: 1em;
	text-align: left;
}
.home_robots .img { display: table-cell; width: 8em; }
.home_robots .data { display: table-cell; text-align: left; vertical-align: top; }
.home_robots .data .title { display: block; font-size: 1.2em; font-weight: bold; text-align: left; margin-bottom: 0.4em; }
.home_robots .data .content { display: block; text-align: left; margin-bottom: 0.2em; }
.home_robots .data .content img { width: 1em; margin-right: 0.5em; }
.home_robots .robot { width: 8em; }
.home_dashboard_main { display: inline-table; vertical-align: top; }
.home_dashboard {
	/*display: inline-table;*/
	background: rgb(245, 245, 245);
	color: rgb(3, 60, 138, 1);
	border: 2px solid rgb(3, 60, 138, 1);
	border-radius: 6px;
	padding: 0.2em;
	min-width: 14.6em;
	min-height: 10.8em;
	margin: 0.4em 0em 0em 0.4em;
	text-align: left;
	font-size: 1.6em;
}
.home_dashboard td { padding: 0.05em !important; border: 0px !important; }
.home_dashboard_statuslist { min-width: 29.8em !important; }
.home_stats {
	background: #D4D4D4;
	color: rgb(3, 60, 138, 1);
	border: 2px solid rgb(3, 60, 138, 1);
	border-radius: 6px;
	padding: 0.2em;
	min-width: 14.6em;
	margin: -0.1em 0em 0.4em 0.4em;
	text-align: left;
	font-size: 1.6em;
}
/*.home_dashboard table { }*/
.home_dashboard .day10 { color: rgb(101, 101, 101); font-size: 0.8em; }
.home_dashboard .lastday { color: rgb(31, 31, 31); font-weight: bold; font-size: 0.9em; }
.home_dashboard .green { color: rgb(1, 191, 1); }
.home_dashboard .yellow { color: rgb(215, 187, 2); }
.home_dashboard .h_red { color: rgb(191, 1, 1); }
.home_dashboard .small { font-size: 0.6em; }
.home_dashboard .news{ font-size: 0.7em !important; }

.home_dashboard .error_head_line { border-bottom: 2px solid rgb(3, 60, 138, 1); }
.home_dashboard .error_head_left { color: rgb(101, 101, 101); font-size: 0.6em; display: inline-table; width: 17%;  }
.home_dashboard .error_head_middle1 { color: #DD0000; font-size: 0.6em; display: inline-table; width: 12%; }
.home_dashboard .error_head_middle2 { color: #DD0000; font-size: 0.6em; display: inline-table; width: 50%; }
.home_dashboard .error_head_right { color: rgb(101, 101, 101); font-size: 0.6em; display: inline-table; text-align: right; width: 21%; }
.home_dashboard .error { color: #DD0000; font-size: 0.6em; width: 100%; border-bottom: 1px solid rgb(3, 60, 138, 1); }
.home_error { min-height: 6em; font-weight: bold; min-width: 29.8em !important; margin: -0.1em 0em 0.4em 0.4em; }

.home_dashboard .customerhead { background: rgb(225, 225, 225); }
	.home_dashboard .customerinfo {
	/*background: rgb(225, 225, 225);*/
	font-size: 0.8em; color: rgb(3, 20, 48, 1);
	min-width: 24.6em;
}
.home_dashboard .customerinfo td, th { border-bottom: 1px solid black !important; }
.home_dashboard .customerinfo td { font-size: 0.8em; }
.home_dashboard .customerinfo td .accus{ font-size: 0.7em; color: rgb(1, 121, 1); }
.home_dashboard .customerinfo td .link{ color: rgb(1, 1, 201); }
.warning { color: #880000; }
a { color: #4e7a92; outline: none; text-decoration: none; }
a:hover, 			/* Durch Maus oder Tastatur angewählte Verweise werden sichtbar gemacht */
a:focus { color: rgba(0, 128, 255, 1); text-decoration: none; }
.showrobot_image { width: 40em; }

.errorlist { border-spacing: 0px; max-width: 80em !important; }
.errorlist td { border-bottom: 2px solid black; padding: 5px; }
.errorlist .descr{ background: rgb(193, 193, 205); border-right: 1px solid black; }
.errorlist .solution{ background: rgb(223, 243, 225); }

/* ------------------------------------- navigation image colors  -------------------------------------- */
.image_active { color: rgba(0, 128, 255, 1); }
.image_mouseover { color: rgba(245, 245, 245, 1); /* color: rgba(212, 212, 212, 1); */ }
.image_mouseover_n { color: rgba(226, 0, 26, 1); }
.right_align { text-align: right; }
.input_number_4 { text-align: right; width: 4em !important; }
.input_number_2 { text-align: right; width: 2em !important; }
.dialogtitle { background: rgb(201, 201, 201) !important; text-align: center !important; padding: 0.4em 1em !important; }
.dialogchoise { color: rgb(3, 60, 138, 1) !important; background: rgb(245, 245, 245) !important; padding: 1em; min-width: 15em; max-width: 25em; }
.dialogbutton {
	border: 1px solid rgb(3, 60, 138, 1) !important;
	border-radius: 0.5em !important;
	background: rgb(245, 245, 245) !important;
	color: rgb(3, 60, 138, 1) !important;
	padding: 0.2em 1em !important;
	margin: 1em 0.5em !important;
	min-width: 10em !important;
}
.dialogbutton :focus { background: rgb(205, 205, 245) !important; }
.dialogbutton :hover { background: rgb(205, 205, 245) !important; }
.checkbox_with_space { width: 2em !important; }n
.export { white-space: nowrap; background: rgb(220, 205, 245); }
.export :hover { background: rgb(200, 185, 220); }
.customer { position: absolute; left: 6.5em; color: rgba(0, 128, 255, 1); margin-left: 1em; margin-top: 0.2em; }
/*.customer { position: absolute; left: 6.5em; color: rgb(255, 94, 0); margin-left: 1em; margin-top: 0.2em; }*/
.abo{ left: 30em !important; }
.customer_select { font-size: 1.1em; font-weight: bold; padding: 0.4em; border-radius: 0.2em; }
.error { color: rgba(200, 40, 0, 1); }

/* -------------------------------------- Statistics -----------------------------------------------------------*/
.pie { width: 20em; max-width: 35em; max-height: 35em; }
.Doughnut { width: 40em; }
.datefield {
	width: 100%;
	/*height: 1.7em; */
	padding: 0 0.2em 0 0.3em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 1em !important;
	background: #f7f7f7;
  border: 1px solid rgba(0,0,0,0.95);
  border-radius: 0.2em;
  margin: 0px;
  box-sizing:border-box;
}
.datefield input { padding: 0 0.2em 0 0.3em !important; }
.datefield_small {
	padding: 0 0.2em 0 0.3em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-size: 1em !important;
	/*background: #f7f7f7;
  border: 1px solid rgba(0,0,0,0.95);
  border-radius: 0.2em;
  box-sizing:border-box;*/
	margin-right: 2em;
}
.datefield_small input { padding: 0 0.2em 0 0.3em !important; background: #f7f7f7; }
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input { padding: 0.1em 0.4em 0.1em 0.5em !important; border: 1px solid rgba(0,0,0,0.90) !important; border-radius: 0.25em; }
.chartselect {
	/*padding: 0 0.2em 0 0.3em !important;*/
	padding: 0.2em 0.5em 0.2em 0.6em !important;
	font-size: 1em;
	max-width: 25em;
	min-height: 1em !important;
  border-radius: 0.25em !important;
	border: 0px;
}
.multi { max-width: 40em !important; }
.em_15 { min-width: 15em; }
.em_20 { min-width: 20em; }
.css-1acyudd-control { border: 1px solid rgba(0,0,0,0.90) !important; border-radius: 0.25em; }
.css-165y52p-control { border: 1px solid rgb(3, 60, 138, 1) !important; border-radius: 0.25em !important; }
.css-tlfecz-indicatorContainer { padding: 2px!important; }
.css-1gtu0rj-indicatorContainer { padding: 2px!important; }
.css-1t8y0t9-control { min-height: 1em!important; }
/* .css-319lph-ValueContainer { padding: 0px 8px !important; }*/
.css-1vp9nu5-control{ min-height:1em !important; }
.hidden { visibility: hidden; position: absolute; width: 1em; }
.stats { max-height: 60em; }
.helplink { color: #3333AA; }
.infoimage { width: 1.2em; }
.need { background: rgba(255, 210, 210, 0.8) !important; }
/*.print_window { height: 0px; width: 0px; position: absolute; }*/
/*@media print {
  @page { margin: 0; }
  /*body { margin: 1.6cm; }
}*/
.etikett_print {
	width: 30em;
	font-weight: bold;
  font-size: 1.2em ;
	border: 0px solid; border-radius: 3px;
	padding: 5px;
	background: rgba(255, 255, 255, 1);
}
.etikett_table { font-size: 1.2em; font-weight: bold; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif; }
.print_ce { margin-bottom: 3px; margin-right: 5px; }
.print_img_top { width: 250px !important; }
.print_img_ce { width: 70px !important; }

/* -------------------------------------- Service Protokoll -----------------------------------------------------------*/
.service_table_main { border: 2px solid rgba(0,0,0,0.95); border-radius: 0.5em; }
.service_table_main th { font-size: 2em; text-align: left; padding-left: 0.6em; }
.service_table_main td { font-size: 1.2em; padding-left: 0.8em; font-weight: bold; }
.service_table_main .logo { font-size: 0.8em; }
.service_table_main hr { border: 1px solid black; margin: 0px 0.8em 0.8em 0.2em; }

.service_table { border: 1px solid rgba(0,0,0,0.95); border-radius: 0.3em; background-color: #FFFFFF; }
.service_table th { font-size: 1.2em; text-Align: left; color: lightgrey; background-color: rgb(77, 77, 77); border-radius: 0.2em; padding: 0.2em; padding-left: 0.4em;  }
.service_table td {  padding: 0.1em; padding-left: 0.3em; border: 1px solid rgba(214, 214, 214, 0.95);}
.service_table input { width: 100%; }
.service_table .checkbox { width: 2em; }
/*.service_table .checkbox input:disabled { accent-color: red; background-color: #FF0F0F; color: #FF0F0F; border: 2px solid rgba(0,0,0,0.95)}*/
.service_table input[type=checkbox]:checked:disabled{
  outline:2px solid red !important;
	background-color: #990F0F !important;
	background: rgba(0, 0, 0, 0) !important;
	/*color: #FFFF0F;*/
	border: 3px solid blue !important;
	color: #EE00CC !important;
	border-radius: 3px;
	/*-webkit-transform: rotate(-45deg);
	-webkit-appearance: none;  */
}
.service_table .number { text-align: right; }
.service_table .top { vertical-align: top; }
.service_table .infoline { background-color: #EEEEEE; border-color: black rgb(200, 200, 200) black rgb(200, 200, 200); border-style: solid; border-width: 1px; padding-bottom: 3px; font-weight: bold; }
.service_table .optional { color: #999999; background-color: #FFFFDD; }
.service_table .old { background-color: #F5ECEC; }
.service_table .insert_old { background-color: #F5ECEC; border: 1px; }
.service_table .insert_med { width: 6em; }
.service_table .insert_large { width: 15em; }
.service_table .insert_very_large { width: 30em; }
.service_table .right { text-align: right; }
.service_table .info { font-size: 0.8em; }
.service_table .force { background-color: #FFDDDD; }
.service_table .small_service_img { width: 10em; }
.service_table .service_img_div { display: inline-table; width: 50%; }
.service_save { font-size: 1.5em; font-weight: bold; }
/*.service_table .bold { font-weight: bold; }*/
.h2_Service { text-align: left; font-size: 1.4em; margin-bottom: 0.2em; }
.hide { visibility: hidden; }
.link { color: #0000CC; }
.pcvisit { width: 1.8em !important; }
.input_tiny { width: 2.3em !important; }
.input_small { width: 4em !important; }
.input_med { width: 8em !important; }
.input_large { width: 18em !important; }
/*
	.css-tlfecz-indicatorContainer {
		color: hsl(0, 0%, 80%);
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 2px!important;
		-webkit-transition: color 150ms;
		transition: color 150ms;
		box-sizing: border-box;
	}
	.css-1t8y0t9-control {
		-webkit-align-items: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: white;
		border-color: hsl(0, 0%, 80%);
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		cursor: default;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		min-height: 5px!important;
		outline: 0!important;
		position: relative;
		-webkit-transition: all 100ms;
		transition: all 100ms;
		box-sizing: border-box;
	} */
/*
	#navigation ul {
		list-style-type: none;
		margin-bottom:30px;
	}
	#navigation ul li.first-child	{ border-top: 1px #DBDBDB solid; }
	#navigation ul li a {
		display: block;
		border-bottom: 1px solid #4e7a92;
		font-size: 1.1em;
		line-height: 1.5em;
		padding: 1em 35px;
		text-decoration: none;
	}
	#navigation ul li a:before{
		content:" ";
		display:inline-block;
		width:1em;
		height:1em;
		border-left:5px solid #4e7a92;;
	}*/
	/*main {
		display: block;
		margin: 0 0.5em 0 0.5em;
		background: #fff;
		width: 100%;
	}*/
	/*
	.ribbon {
		display: inline-block;
		position: relative;
		margin: 2em 0 2em -1.5em;
		padding: 0.5em 1em;
		background: #4e7a92;
		box-shadow: 0px 1px 3px rgba(0,0,0,.8);
	}
	.ribbon::before{
		display: block;
		width: 1.5em;
		height: 0;
		position: absolute;
		bottom: -1.5em;
		left: 0em;
		content: "";
		border-bottom: 1.5em solid transparent;
		border-right: 1.5em solid rgb(0, 80, 116);
	}
	.searchbar {
		display: inline-block;
	}*/
	/** CONTENT **/
	/*
	h1,
	h2 {
		font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
		padding-left: 1em;
		color: #666;
		text-transform: uppercase;
		border-left:5px solid #4e68AD;
	}
	h2 span {
		font-size: 0.70em;
		color: #818181;
		text-transform: none;
	}
	h3	{
		font-size: 1.25em;
		font-weight: normal;
		padding: 0px;
		margin: 0px;
		color: #4E68AD;
	}
	table {
		border-spacing: 1px;
		width: 100%;
	}
	table.dataout {
		font-size: 0.8em;
	}
	tr {
		padding: 0px;
		margin: 0px;
	}
	th {
		margin: 0px;
		padding: 1px 2px;
		background-color: rgb(201, 201, 201);
	}
	td {
		margin: 0px;
		padding: 1px;
		vertical-align: top !important;
	}
	.light {
		background-color: #E4E4E4;
	}
	.dark {
		background-color: #AFC9DF;
	}
	/*p {
		text-align: left;
		line-height: 1.6em;
		padding: 20px 0px
	}*/
	/*
	a.more	{
		float: right;
		font-weight: bold;
	}
	a.more:after{
		content:" \2192 ";
		font-size:1.2em;
		font-weight:bold;
	}
	ul.square {
		list-style-type:square;
		color: #999999;
		margin: 0 0 0.5em 0.5em;
		font-size: 0.8em;
		font-weight: bold;
	}
	ul.square li{
		padding:10px;
	}*/
	/*DL, DT, DD Datenliste*/
	/*
	dl.grid {
		display: grid;
		grid-template-columns: 1fr 100%;
	}
	dd {
		margin: 0;
		padding-left: 1em;
	}
	dd span:first-child {
		display: inline-block;
		width: 4em;
	}
	dl.grid dd {
		margin-bottom: 1em;
	}
	a[href^="tel"] { white-space: nowrap; }
	ul#footer-nav li {
		list-style-type: none;
		displaY: inline;
		padding: 0 0.5em;
		border-right: 1px  solid #ACACAC;
	}
	footer {
		display: grid;
			grid-template-columns: repeat(2, 1fr);
		padding: 1em;
	}
	ul#footer-nav  li:last-child {
		border: none;
	}
	ul#footer-nav img {
		width: 1.5em;
	}
	ul#footer-nav a:hover,
	ul#footer-nav a:focus,
	ul#footer-nav a:active {
		background: white;
	}
	footer p {
		text-align: right;
	}
	nav a[aria-current=page]{
		background: #aaa;
	}
	form {
		display: inline;
		flex-flow: row wrap;
		align-items: center;
		width: 100%;
	}
	input {
		display: inline;
		padding: 2px;
	}
	input[type="submit"] {
		flex-flow: row wrap;
		font-size: 1.1em; padding: 3px 10px;
		font-family: Roboto, sans-serif;
		font-weight: 300;
		color: #4e7a92;
		border: 1px solid silver;
		background-image: linear-gradient(to top, gainsboro 0%, white 90%);
		border-radius: 15px;
		margin: 2px 5px;
	}
	*/
	/* ========== repsonsives Grid-Layout ========== */

	/* mobile first - auf schmalen Bildschirmen wird alles untereinander platziert. */
	/* ab einer Breite von 30em haben zwei Spalten nebeneinander Platz: */
	/*
	@media (min-width: 30em) {
		main {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 1em;
		}
		nav {
			grid-column: 1 / 2;
		}
		#intro {
			grid-column: 2 / 3;
			grid-row:    1 / 2;
		}
		article {
			grid-column: 2 / 3;
		}
		.spalte img {
			width: 50%;
			float: left;
			margin-right: 0.5em;
		}
	}

	@media (min-width: 50em) {
		main {
			grid-template-columns: repeat(3, 1fr);
		}
		nav {
			grid-row: 1 / 4;
		}
		#intro {
			grid-column: 2 / 4;
			grid-row:    1 / 2;
		}
		article {
			grid-column: 2 / 4;
		}
	aside {
			grid-column: 3 / 4;
			grid-row: 1 / 2;
		}
		.spalte {
			grid-row:    2 / 3;
		}
		.spalte img {
			width: 33%;
		}
	}
*/
