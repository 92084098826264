.rdt_Table { font-size: 0.8em !important; }
.rdt_TableHeader { background-color: #AFC9DF !important; font-size: 1.1em !important; min-height: 35px !important; }
.rdt_TableHeadRow {  background-color: #C9C9C9 !important; font-size: 1.1em !important; margin: 0px !important; padding: 0px !important; min-height: 30px !important; }
.rdt_TableRow { margin: 1px !important; padding: 0px !important; min-height: 25px !important; }

/*---------------------------------------------------------Moveable Div for additional content ---------------------------------------------------------------------------------*/
.handle { width: 95%; }
.handle100 { width: 100%; padding-bottom: 0.5em; }
.handle_text { padding: 0.2em; }
.infotable {
  font-size: 0.9em;
  position: absolute;
  top: 6em;
  left: 20%;
  min-width: 32em;
  max-width: 55em;
  border: 2px solid #999999;
  background-color: rgb(215, 235, 255);
  border-radius: 0.5em;
  /*background-color: #d1d4c0;*/
}
.infotable img {width: 18px; margin: -1px 0.4em;}
.infotable .close{ float: right; width: 1em; width: 1.2em; padding: 1px 0px; margin: 0px !important; }
.infotable .header{ font-size: 1.2em; background-color: #B9B9B9; font-weight: bold; padding: 0.3em; }
.infotable .header_inline{ background-color: #B9B9B9;  display: inline-block; padding: 0.2em; }
.infotable .body{ padding: 0.5em; }
.infotable button{
  border: none;
  /*background: none;*/
  background-color: #f7f7f7;
  color: rgba(0, 98, 200, 1);
  padding-right: 0.6em;
  margin-bottom: 0.5em;
  margin-right: 0.6em;
  border-right: 1px solid rgba(0, 68, 140, 1);
	border-top: 1px solid rgba(100,100,100,0.4);
	border-left: 1px solid rgba(0,0,0,0.4);
	border-bottom: 1px solid rgba(3, 40, 108, 1);
	border-right: 1px solid rgba(3, 40, 108, 1);
  border-radius: 0.5em;
}
.infotable button:hover{
	color: rgb(3, 60, 138, 1);
  background-color: #B9B9B9;
	border-top: 1px solid rgba(100,100,100,0.4);
	border-left: 1px solid rgba(0,0,0,0.4);
	border-bottom: 1px solid rgba(3, 40, 108, 1);
	border-right: 1px solid rgba(3, 40, 108, 1);
}
.infotable li { margin-bottom: 0.4em; }
/*.infotable .handle { display: inline-block; }*/
.infoimage { position: absolute; top: 6em; left: 20%; /*background-color: rgb(215, 235, 255);*/ border-radius: 0.5em; visibility: hidden; }
.infoimage table{ border: 2px solid #999999; background-color: rgb(215, 215, 215); }
.infoimage .header{ font-size: 1.2em; background-color: #B9B9B9; font-weight: bold; padding: 0.3em; }
.infoimage .close{ float: right; width: 1em; width: 1.2em; padding: 1px 0px; margin: 0px !important; }
.infoimage .header img {width: 18px; margin: -1px 0.4em;}

/*-------------------------- Additional edit windows -----------------------------------------*/
.fixtable { position: relative !important; top: 0 !important; left: 0 !important; width: 25%; max-width: 120em !important; min-width: 52em !important; }
.edittable {
  font-size: 0.9em;
  font-weight: bold;
  position: absolute;
  top: 14.5em;
  left: 20%;
  min-width: 32em;
  max-width: 80em;
  border: 2px solid #999999;
  border-radius: 0.3em;
  background-color: #C9C9C9;
  padding: 1px;
}
.large_fix {width: 35% !important;}
.edittable .header{ font-size: 1em; background-color: #B9B9B9; font-weight: bold; padding: 0.2em; min-width: 32em;}
.edittable .header_inline{ background-color: #B9B9B9;  display: inline-block; padding: 0.2em; }
.edittable .header_close{ width: 1.2em }
.edittable .close{ float: right; width: 1.2em; padding: 1px 0px; margin: 0px !important; }
.edittable .fields { background: #f7f7f7; margin-bottom: 0.2em; }
.edittable table {
  margin: 0.1em;
  min-width: 21em;
  max-width: 40em;
  width: 99%;
  /*border: 1px solid #999999;*/
  /*background-color: #AFC9DF !important;
  font-size: 1.1em !important;*/
  border-spacing: 1px;
  min-height: 2em !important;
}
.edittable table th { border: 1px solid #999999; font-weight: bold; background-color: #AFC9DF; min-height: 2em !important; }
.edittable table td { background-color: #f7f7f7; }
/*.edittable input { max-width: 8em; }*/
.edittable .checkbox { width: 1.5em !important; }
.edittable input:disabled { background-color: #BBBBBB; }
.edittable .file { display: none; }
.custom-file-upload { border: 1px solid #ccc; display: inline-block; padding: 6px 12px; cursor: pointer; }
.edittable .number { max-width: 6em; }
.edittable select { min-width: 10em; height: 1.6em; }
.edittable .longinput { max-width: 30em !important; margin-left: 0.1em; }
.edittable .input12em { max-width: 12em; margin-left: 0.1em; }
.edittable .action { width: 2em; margin-right: 1em; }
.edittable .action_line { width: 1.2em; margin-left: 1em; }
.edittable .nowarp { white-space: nowrap; width: 10em; }
.edittable img {width: 18px; margin: -1px 0.4em;}
.edittable .dateInput { width: 8em; background: #f7f7f7; border-radius: 0.3em; }
.edittable_large { min-width: 35em !important; }
.edittable_large_window { width: 50em;  max-width: 120em !important; }
.edittable .recipe_feeds { min-width: 60em !important; }

/* --------------------------------------------------------------------------------------------------------------------- */
.button {
  font-size: 1.0em;
  background-color: #f7f7f7;
  margin-right: 1em;
  font-weight: bold;
  max-width: 40em !important;
  min-height: 2.1em;
  border:2px solid rgba(3,40,108,0.6); border-radius: 0.5em;
	border-top: 2px solid rgba(3,40,108,0.4);
	border-left: 2px solid rgba(3,40,108,0.4);
  padding: 0em 1.4em 0em 1.4em;
}
.small{ font-size: 0.8em !important; padding: 0em 1.0em 0em 1.0em !important; }
.button:hover{
  color: rgb(3, 60, 138, 1);
  background-color: #B9B9B9;
	border-top: 2px solid rgba(80,80,180,0.4);
	border-left: 2px solid rgba(80,80,180,0.4);
	border-bottom: 2px solid rgba(3, 40, 108, 1);
	border-right: 2px solid rgba(3, 40, 108, 1);
}
.smallbutton {
  font-size: 0.8em !important;
  font-weight: bold !important;
  /*border-radius: 2px;*/
  max-width: 40em !important;
  min-width: 40px !important;
  padding: 1px 2px;
  min-height: 25px !important;
  border-radius: 0.5em;
}
.table_buttons { margin: 0 0.4em 0.4em 0.4em; vertical-align: top; }
.table_buttons button{
  font-size: 0.9em;
  margin-right: 1em;
  font-weight: bold;
  min-height: 2.1em;
  border:2px solid rgba(3,40,108,0.6); border-radius: 0.5em;
	border-top: 2px solid rgba(3,40,108,0.4);
	border-left: 2px solid rgba(3,40,108,0.4);
  padding: 0em 1em 0em 1em;
}
.table_buttons button:hover{
  /*background-color: #B9B9B9;*/
  color: rgb(3, 60, 138, 1);
  background-color: #B9B9B9;
	border-top: 2px solid rgba(80,80,180,0.4);
	border-left: 2px solid rgba(80,80,180,0.4);
	border-bottom: 2px solid rgba(3, 40, 108, 1);
	border-right: 2px solid rgba(3, 40, 108, 1);
}
.table_buttons .button_active { background-color: #A4F6A0;}
.table_buttons img{ width: 1.5em; vertical-align: middle; }
.right_side {	float: right; vertical-align: middle; font-size: 1.15em;  }
.radiotext { position: relative; top: -0.25em; padding-right: 0.4em; }
.table_input { margin-right: 1em; }
.img_inline { margin: 0px 2px 0px 2px !important; padding: 0px !important; }
.errors { padding-left: 1em; }
.errors .error { color: #DD0000 !important; padding-right: 1em; }
.errors .warning { color: #880000 !important; padding-right: 1.5em; }
.errors .info { color: #009999; padding-right: 1.5em; }
.erors_inline { display: inline-block; margin-right: 1.5em; }
.checkbox_row { display: list-item; }
.check_new_column { display: inline-block; vertical-align: top; background: #f7f7f7; padding-right: 1em; }

/* ---------------------------------------------- drives list ------------------------------------------------------------*/
.dr_table { display: inline-flex; margin-right: 1em; margin-bottom: 0.6em; border: 1px solid #000000; background-color: #000000; font-weight: bold; min-width: 38em; width: 45em; max-width: 50em; vertical-align: top; }
.dr_table .names, .dr_table .amount { display: inline-block; padding-right: 0.4em; }
.dr_table .na { display: inline-block;  width: 24%}
.dr_table .amount { text-align: right; float:right; }
.dr_table .dr_th, .dr_table th, .dr_table td {  border: 1px solid #000000; padding: 0.15em; }
.dr_table th { background-color: rgb(180, 180, 180); font-size: 1.2em; }
.dr_table .dr_th { background-color: rgb(180, 180, 180); font-size: 1.0em; }
.dr_table td { background-color: rgb(228, 228, 228); border-bottom-width: 2px;}
.dr_table .td_top { vertical-align: top; padding: 0em; width: 4em }
.dr_table .td_past { background-color: rgb(210, 255, 200); }
.dr_table .td_past_err { background-color: rgb(255, 200, 180); }
.dr_table .td_active { background-color: rgb(255, 249, 180); }
.dr_table .td_update { background-color: rgb(200, 210, 255) !important; }
.dr_table .td_img{  width: 3.6em; vertical-align: center; }
.dr_table .td_robot { width: 1.2em; }
.dr_table .starttime, .dr_table .runtime  { width: 100%; text-align: center; border: 0px solid #000000; padding: 0.1em }
.dr_table .starttime  { border-bottom-width: 2px; }
.dr_table .starttime { color: #046600; }
.dr_table .runtime { color: #993300; font-size: 0.8em; }
.dr_table .late_runtime { font-size: 0.7em; }
.dr_table .img { width: 2.3em; }
.dr_table .img_small { width: 1.3em; }
.dr_table .center { text-align: center; }
.dr_table .groups { border: 0px solid;}
.dr_table .groups .names { min-width: 5em; }
.dr_table .groups .a { font-size: 0.8em !important; border-right: 1px solid; padding-right: 0.2em;}
.dr_table .groups td { background-color: rgba(88, 62, 216, 0) !important; padding: 0px; margin: 0px; }
.dr_table .now { background-color: rgb(230, 140, 140); text-align: center; padding: 0em; }
.dr_top {  min-width: 13em !important; width: 23em !important; font-size: 1em !important; }
.dr_fg { width: 23em !important; }
.dr_command { width: 19em !important; border: 0px solid #000000 !important; background-color: rgb(228, 228, 228) !important; }
.dr_command .dr_td {  border: 0px solid #000000 !important; font-weight: normal; padding-right: 0.4em; }
.dr_robot { width: 14.5em !important; }
.dr_top .img { width: 1.8em !important; }
.dr_top td { border-bottom-width: 1px !important; }
.dr_top th { font-size: 1.0em !important; }
.dr_top .robot_info { font-size: 0.8em; margin-top: -0.2em; }
.dr_table .date { width: 10em; }
.dr_table .changed { color: #993300; }
.testentry { font-size: 0.7em; vertical-align: top;}
.only_support { background-color: rgb(160, 200, 255) !important; }

.robot_0 { background-color: rgba(88, 62, 216, 0.875) !important; }
.robot_1 { background-color: rgb(4, 99, 9) !important; }
.robot_2 { background-color: rgb(209, 126, 2) !important; }
.robot_3 { background-color: rgb(146, 0, 179) !important; }

.bull { width: 1.7em !important;}
.inline { display: inline-block; vertical-align: top; }

.errorlist_description { color: #000000; }
.errorlist_error { color: #999999; }
.errorlist_option { color: #993300; font-size: 0.8em; }
.errorlist_solution { color: #00a82d; }
.errorlist_solution_service { color: #005717; }
.errorlist_solution_pdf { color: #0e00cf; }

.uploadform {
  border: 2px solid #999999;
  background-color: rgb(215, 235, 255);
  border-radius: 0.5em;
  padding: 0.2em;
  max-width: 90em;
  display: inline-flex;
}
.smaller { font-size: smaller !important; }
.tiny { font-size: medium !important; }
.larger { font-size: larger !important; }

.table_select {
	/*padding: 0 0.2em 0 0.3em !important;*/
	padding: 0.1em 0.25em 0.1em 0.3em !important;
	font-size: 0.9em;
	max-width: 25em;
	min-height: 0.9em !important;
  height: 0.9em;
  border-radius: 0.25em !important;
	border: 0px;
}

.selectlist {
  padding: 0px; margin: 0px;
}
datalist {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto
}
/*option {
  background-color: rgb(182, 54, 54);
  padding: 4px;
  color: blue;
  margin-bottom: 1px;
  font-size: 18px;
  cursor: pointer;
}*/
.icon { font-size: 1.5em; color: black; }
.icon_s { font-size: 1.0em; }
.icon_l { font-size: 1.8em; }
.icon_a { font-size: 1.6em; color: black; margin-right: 0.2em; }
/*.icon_large { font-size: 1.6em !important; font:icon; }*/
.icon_a:hover { color: blue; }
.green { color: green; }
.darkred  { color: darkred; }
.grey  { color: darkgrey; }
.icon1 { font-size: 1.8em; }
option:hover,  .active{
  background-color: lightgreen;
}
.icon_button { font-size: 1.4em !important; color: black; border: none !important; margin: 0px !important; padding: 0px !important; min-height: 1.5em !important; background-color: rgba(0, 0, 0, 0) !important;}
.icon_link { font-size: 1.9em; display: block; background-size: contain; background-repeat: no-repeat; display: inline; cursor: pointer; }
.i_info { background-image: url('https://connect.hetwin.at/public/img/info.png'); }
.i_info:hover { background-image: url('https://connect.hetwin.at/public/img/info_mouseover.png'); }
.i_add_col { background-image: url('https://connect.hetwin.at/public/img/add_org.png'); }
.i_add_col:hover { background-image: url('https://connect.hetwin.at/public/img/add_mouseover.png'); }
.i_email { background-image: url('https://connect.hetwin.at/public/img/email.png'); }
.i_email:hover { background-image: url('https://connect.hetwin.at/public/img/email_mouseover.png'); }
.i_key { background-image: url('https://connect.hetwin.at/public/img/key.png'); }
.i_key:hover { background-image: url('https://connect.hetwin.at/public/img/key_mouseover.png'); }
.i_bug { background-image: url('https://connect.hetwin.at/public/img/bug.png'); }
.i_bug:hover { background-image: url('https://connect.hetwin.at/public/img/bug_mouseover.png'); }
.i_pcvisit { background-image: url('https://connect.hetwin.at/public/img/pc-visit.png'); }
.i_pcvisit:hover { background-image: url('https://connect.hetwin.at/public/img/pc-visit_mouseover.png'); }
.i_client { background-image: url('https://connect.hetwin.at/public/img/client.png'); }
.i_client:hover { background-image: url('https://connect.hetwin.at/public/img/client_mouseover.png'); }
