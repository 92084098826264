.register_security_text {
  resize: none;
  color: rgba(84, 84, 95, 1);
}
.register_window {
  width: 62em !important;
}
.password_request_window {
  width: 22em !important;
}
.register_window .checkbox{
  width: 3em !important;
}

.login-window {
  align-items: left;
  margin-left: 20em;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border:2px solid rgba(3, 60, 138, 1);
  border-radius: 8px;
  padding: 20px;
  width: 22em;
  margin: 5px;
  background-color: rgba(234, 234, 255, 0.4);
}

.login-wrapper input {
  width: 100%;
}
.login-wrapper .login_button {
  border:2px solid rgba(3, 60, 138, 1);
  border-radius: 3px;
  width: 60%;
  padding: 3px;
  font-size: 1.1em;
  margin: 20px;
}
.login-wrapper div {
  width: 100%;
  padding-bottom: 10px;
}

.login-wrapper .welcome {
  margin-bottom: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.login-wrapper .close {
  float: right;
  width: 1em;
  margin: 1px 1px;
}

.img_languages {
  width:3em;
  padding-right: 1em;
}

.login-wrapper-window{
  display: flex;
  flex-direction: column;
  align-items: center;
  border:2px solid rgba(3, 60, 138, 1);
  border-radius: 8px;
  margin: 5px;
  background-color: rgba(234, 234, 255, 0.4);
  top: 12.7em;
  min-height: 15em;

  position: absolute;
  background-color: rgba(244, 244, 255, 1);
  width: 22em;
}
.login-wrapper-window .headline{
  background-color: rgba(234, 234, 255, 1);
  border-bottom:2px solid rgba(3, 60, 138, 1); 
  border-radius: 8px;
  padding: 2px;
}
.login-wrapper-window .body{
  padding: 20px;
}
.login-wrapper-window input {
  border: 1px solid black;
  border-radius: 3px;
  width: 20em;
}
.login-wrapper-window .alternative input {
  background-color: rgba(245, 224, 255, 0.8);
}
.testcolor {
  color: rgba(0, 155, 120, 0.5); 
  color: rgba(0, 255, 0, 0.65);
  color: rgba(0, 120, 25, 0.5);
  color: rgba(120, 255, 120, 0.6); 
  color: rgba(120, 155, 0, 0.5); 
  color: rgba(0, 152, 155, 0.65);
  color: rgba(53, 222, 0, 0.5); 
  color: rgba(0, 205, 0, 0.4); 
  color: rgba(0, 255, 255, 0.6); 
  color: rgba(0, 242, 55, 0.5);
  color: rgba(83, 182, 15, 0.5);
  color: rgba(13, 252, 255, 0.5);
  color: rgba(55, 125, 0, 0.7);
  color: rgba(0, 120, 255, 0.5);

  color: rgba(255, 0, 0, 0.7) ;
  color: rgba(255, 0, 255, 0.7);
  color: rgba(255, 118, 5, 0.75);
  color: rgba(255, 0, 255, 0.5);
  color: rgba(255, 0, 205, 0.6); 
  color: rgba(255, 0, 0, 0.4);
  color: rgba(187, 28, 255, 0.7); 
  color: rgba(241, 62, 0, 0.6); 
  color: rgba(155, 1, 68, 0.8);
  color: rgba(165, 30, 0, 0.6);
  color: rgba(255, 0, 35, 0.5);
  color: rgba(253, 46, 232, 0.6); 
  color: rgba(255, 20, 20, 0.6);
  color: rgba(255, 2, 135, 0.5);
  color: rgba(155, 0, 120, 0.5);
  
  color: rgba(240, 225, 0, 0.6); 
  color: rgba(193, 162, 15, 0.5);
  color: rgba(250, 170, 0, 0.6);
  color: rgba(183, 162, 135, 0.5);
  color: rgba(133, 80, 0, 0.5); 
  color: rgba(53, 72, 125, 0.5);
  color: rgba(253, 202, 0, 0.5); 
  color: rgba(253, 162, 25, 0.5);
  color: rgba(120, 15, 0, 0.5);
  color: rgba(70, 60, 30, 0.6); 
  color: rgba(225, 118, 5, 0.6);
  color: rgba(255, 120, 120, 0.5);

  color: rgba(0, 0, 255, 0.4);
  color: rgba(55, 0, 180, 0.5);
  color: rgba(0, 55, 180, 0.5); 
  color: rgba(0, 0, 255, 0.7);
  color: rgba(0, 55, 125, 0.7);
  color: rgba(0, 0, 255, 0.5);
  color: rgba(93, 0, 235, 0.5);
  color: rgba(20, 20, 255, 0.6); 
  color: rgba(83, 82, 235, 0.5);



  color: rgba(255, 0, 0, 0.4); 
  color: rgba(0, 0, 255, 0.5); 
  color: rgba(0, 255, 0, 0.4); 
  color: rgba(255, 0, 255, 0.5); 
  color: rgba(240, 225, 0, 0.5); 
  color: rgba(0, 255, 255, 0.5); 
  color: rgba(255, 120, 120, 0.5); 
  color: rgba(120, 255, 120, 0.5); 
  color: rgba(120, 120, 255, 0.5); 
  color: rgba(155, 0, 120, 0.5); 
  color: rgba(0, 155, 120, 0.5); 
  color: rgba(120, 155, 0, 0.5); 
  color: rgba(55, 0, 180, 0.5); 
  color: rgba(0, 55, 180, 0.5); 
  color: rgba(180, 55, 0, 0.5); 
  color: rgba(255, 0, 0, 0.7);
  color: rgba(0, 255, 0, 0.7);
  color: rgba(0, 0, 255, 0.7);
  color: rgba(125, 0, 55, 0.7);
  color: rgba(55, 125, 0, 0.7);
  color: rgba(0, 55, 125, 0.7);
  color: rgba(193, 162, 15, 0.5); 
  color: rgba(255, 132, 5, 0.6); 
  color: rgba(153, 162, 135, 0.5); 
  color: rgba(120, 60, 255, 0.4); 
  color: rgba(250, 220, 0, 0.5); 
  color: rgba(165, 0, 0, 0.5); 
  color: rgba(93, 0, 235, 0.5); 
  color: rgba(30, 50, 50, 0.5); 
  color: rgba(133, 80, 0, 0.5); 
  color: rgba(53, 222, 0, 0.5); 
  color: rgba(13, 252, 255, 0.5); 
  color: rgba(53, 72, 125, 0.5); 
  color: rgba(255, 0, 35, 0.5); 
  color: rgba(253, 202, 0, 0.5); 
  color: rgba(20, 20, 255, 0.6); 
  color: rgba(0, 242, 55, 0.5); 
  color: rgba(120, 15, 0, 0.5);
  color: rgba(83, 182, 15, 0.5); 
  color: rgba(83, 82, 235, 0.5);
  color: rgba(253, 162, 25, 0.5);
  color: rgba(255, 2, 135, 0.5);
  color: rgba(0, 152, 155, 0.5); 
  color: rgba(255, 0, 255, 0.5); 
  color: rgba(0, 120, 25, 0.5);
  color: rgba(0, 120, 255, 0.5);
}